<template>
  <vc-viewer
    style="height: 90%"
    ref="vcViewer"
    :animation="animation"
    :base-layer-picker="baseLayerPicker"
    :timeline="timeline"
    :info-box="infoBox"
    :showCredit="false"
    :skyBox="false"
    :sceneMode="1"
    :contextOptions="{
      webgl: {
        alpha: true,
      },
    }"
    @ready="onReady"
  >
    <vc-datasource-geojson
      data="http://geo.datav.aliyun.com/areas_v3/bound/100000_full.json"
      @ready="onDatasourceReady"
      stroke="red"
    ></vc-datasource-geojson>
  </vc-viewer>
</template>
<script>
import axios from "axios";
export default {
  name: "cesium-map",
  data() {
    return {
      loading: true,
      animation: false,
      timeline: false,
      baseLayerPicker: false,
      infoBox: false,
      globe: false,
      color: [],
      provider: null,
      alpha: 1,
      brightness: 1,
      contrast: 1,
      offset: [10, 25],
      otherOpts: {
        offset: [0, 32],
        position: "bottom-right",
      },
    };
  },
  methods: {
    onReady({ viewer }) {
      if (viewer) {
        this.viewer = viewer;
        viewer.scene.globe.show = false;
        viewer.scene.backgroundColor = new Cesium.Color(0.0, 0.0, 0.0, 0.0);
        var center = Cesium.Cartesian3.fromDegrees(103.73, 36.03);
        var heading = Cesium.Math.toRadians(0.0);
        var pitch = Cesium.Math.toRadians(-35.0);
        viewer.camera.lookAt(
          center,
          new Cesium.HeadingPitchRange(heading, pitch, 6000000)
        );
      }
    },
    //添加标签
    addLabel(position, label) {
      this.viewer.entities.add({
        position: position,
        label: {
          text: label,
          fillColor: Cesium.Color.WHITE,
          scale: 0.5,
          font: "normal 60px MicroSoft YaHei",
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 9000000),
          scaleByDistance: new Cesium.NearFarScalar(50000, 1, 500000, 0.5),
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          pixelOffset: new Cesium.Cartesian2(0, -10),
          outlineWidth: 20,
          outlineColor: Cesium.Color.BLACK,
        },
      });
    },

    //获取坐标串
    getDegreesArrayHeights(feature) {
      let degreesArrayHeights = [];
      let coordinates;
      if (feature.geometry.type == "MultiPolygon") {
        coordinates = feature.geometry.coordinates[0][0];
      } else if (feature.geometry.type == "Polygon") {
        coordinates = feature.geometry.coordinates[0];
      }
      //坐标串转为需要的格式[x,y,z,x,y,z....]
      for (let i = 0; i < coordinates.length; i++) {
        const element = coordinates[i];
        degreesArrayHeights.push(element[0]);
        degreesArrayHeights.push(element[1]);
        degreesArrayHeights.push(100);
      }
      return degreesArrayHeights;
    },
    onDatasourceReady({ cesiumObject }) {
      cesiumObject.entities.values.map(({ polygon }) => {
        polygon.material = Cesium.Color.fromAlpha(Cesium.Color.SLATEGREY, 1.0);
        polygon.extrudedHeight = Math.floor(Math.random() * 200000 + 100000);
        // polygon.outlineColor = Cesium.Color.fromCssColorString("#283954");
        (polygon.outlineColor = new Cesium.PolylineGlowMaterialProperty({
          glowPower: 0.1,
          color: Cesium.Color.ORANGERED.withAlpha(0.9),
        })),
          2;
      });
      this.addPoint();
      this.hoverPickEntity();
    },
    hoverPickEntity() {},

    selectedEntityChanged(obj) {
      if (obj && obj.polygon) {
        obj.polygon.material = Cesium.Color.fromAlpha(Cesium.Color.RED, 1.0);
        obj.polygon.outlineColor = Cesium.Color.fromCssColorString("#483954");
      }
    },
    pagePosition2cartographic(position) {
      const viewer = this.viewer;
      const { camera } = viewer;
      let earthPosition = camera.pickEllipsoid(position, viewer.scene.globe.ellipsoid);
      return cartesian2cartographic(earthPosition);
    },
    addPoint() {
      var pixelSize1 = 18; //定义点初始半径
      var ha = true;
      var pixelMax = 35; //定义点最大半径
      let markerOpacity = 0.8; //定义点初始透明度
      var a = true;
      var position1 = Cesium.Cartesian3.fromDegrees(114.37233209609987, 30.491, 200000);
      var entity1 = this.viewer.entities.add({
        //添加点
        point: {
          show: true,
          pixelSize: new Cesium.CallbackProperty((time, result) => {
            //动态修改点半径
            if (pixelSize1 >= 36) {
              pixelSize1 = 18;
            }
            return (
              ha
                ? ((pixelSize1 += 1), pixelSize1 >= pixelMax && (ha = false))
                : ((pixelSize1 = 36), (ha = true)),
              pixelSize1
            );
          }, false),
          outlineColor: new Cesium.CallbackProperty(() => {
            return Cesium.Color.fromAlpha(Cesium.Color.RED, 0);
          }),
          outlineWidth: 5,
          color: new Cesium.CallbackProperty(function color(time, result) {
            //动态修改点颜色透明度
            if (markerOpacity <= 0) {
              markerOpacity = 0.8;
            }
            return (
              ha
                ? ((markerOpacity -= 0.03), markerOpacity <= 0 && (a = false))
                : ((markerOpacity = 0.8), (a = true)),
              Cesium.Color.YELLOW.withAlpha(markerOpacity)
            );
          }, false),
          heightReference: 200,
        },
        position: position1, //经纬度
        label: {
          //可以在点得旁边显示字
          text: "aaaaa",
          scale: 0.8,
          fillColor: Cesium.Color.YELLOW,
          heightReference: 25000,
          verticalOrigin: Cesium.VerticalOrigin.LEFT,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          //pixelOffset: new Cesium.Cartesian2(10, 20)
        },
      });
    },
    cartesian2cartographic(earthPosition) {
      const viewer = this.viewer;
      if (!viewer || !earthPosition) return { lat: 0, lng: 0, height: 0 };
      const { camera } = viewer;
      let { latitude: lat, longitude: lng, height } = Cesium.Cartographic.fromCartesian(
        earthPosition,
        viewer.scene.globe.ellipsoid,
        new Cesium.Cartographic()
      );
      return {
        lat: Cesium.Math.toDegrees(lat),
        lng: Cesium.Math.toDegrees(lng),
        height,
      };
    },
  },
  mounted() {},
};
</script>
